import { Injectable } from "@angular/core";
import { AxiosApiClient, ProductDto, ProductsApi } from "@smallstack/axios-api-client";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { PageableCrudStore } from "@smallstack/store";

@Injectable({ providedIn: "root" })
export class ProductsStore extends PageableCrudStore<ProductDto> {
  constructor(private axiosApiClient: AxiosApiClient) {
    super();
    this.query$.next({ page: 1, size: 100, sort: "-id" });
  }

  protected async createModel(product: ProductDto): Promise<ProductDto> {
    const res = await this.axiosApiClient.get(ProductsApi).createProduct({ product });
    return res.data;
  }
  protected async patchModel(id: string, product: Partial<ProductDto>): Promise<ProductDto> {
    const res = await this.axiosApiClient.get(ProductsApi).patchProduct({ product, id });
    return res.data;
  }
  protected async putModel(product: ProductDto): Promise<ProductDto> {
    const res = await this.axiosApiClient.get(ProductsApi).putProduct({ product, id: product.id });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<any> {
    const res = await this.axiosApiClient.get(ProductsApi).deleteProduct({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(ProductsApi).deleteManyProducts({ ids });
    return res.data;
  }
  protected async loadModels(query: ApiQueryRequest): Promise<Page<ProductDto>> {
    const res = await this.axiosApiClient.get(ProductsApi).getProducts(query);
    return res.data;
  }
  protected async loadModelById(id: string): Promise<ProductDto> {
    const res = await this.axiosApiClient.get(ProductsApi).getProduct({ id });
    return res.data;
  }
}
