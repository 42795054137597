import { Injectable } from "@angular/core";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { AxiosApiClient, PriceZoneDto, PriceZonesApi } from "@smallstack/axios-api-client";
import { TypeDto } from "@smallstack/axios-api-client";
import { PageableCrudStore } from "@smallstack/store";
import { PriceZoneType, TypeSupport, toTypeDto } from "@smallstack/typesystem";

@Injectable({ providedIn: "root" })
export class PriceZoneStore extends PageableCrudStore<PriceZoneDto> implements TypeSupport {
  constructor(private axiosApiClient: AxiosApiClient) {
    super();
  }

  public async getType(): Promise<TypeDto> {
    return toTypeDto(PriceZoneType);
  }

  protected async createModel(model: PriceZoneDto): Promise<PriceZoneDto> {
    const res = await this.axiosApiClient.get(PriceZonesApi).createPriceZone({ priceZone: model });
    return res.data;
  }
  protected async patchModel(id: string, model: Partial<PriceZoneDto>): Promise<PriceZoneDto> {
    const res = await this.axiosApiClient.get(PriceZonesApi).patchPriceZone({ id, priceZone: model });
    return res.data;
  }
  protected async putModel(model: PriceZoneDto): Promise<PriceZoneDto> {
    const res = await this.axiosApiClient.get(PriceZonesApi).putPriceZone({ id: model.id, priceZone: model });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<any> {
    const res = await this.axiosApiClient.get(PriceZonesApi).deletePriceZone({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(PriceZonesApi).deleteManyPriceZones({ ids });
    return res.data;
  }
  protected async loadModels(query: ApiQueryRequest): Promise<Page<PriceZoneDto>> {
    const res = await this.axiosApiClient.get(PriceZonesApi).getPriceZones(query);
    return res.data;
  }
  protected async loadModelById(id: string): Promise<PriceZoneDto> {
    const res = await this.axiosApiClient.get(PriceZonesApi).getPriceZone({ id });
    return res.data;
  }
}
